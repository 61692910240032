<template>
  <div>
    <Table
      title-key="name"
      :actions="actions"
      :items="$store.state.event.tableData"
      :fields="$store.getters['event/tableFields']"
      :loading="$store.state.event.loadingTable"
      :pagination="$store.state.event.tablePagination"
      :detail="$store.getters['event/detailData']"
      :filter="$store.state.event.tableFilter"
      :sort="$store.state.event.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      @changeFields="changeFields"
      @filter="filterData"
      @sort="sortData"
      @load="loadData"
      @loadDetail="loadDetail"
      @paginate="paginate"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'

import {
} from 'bootstrap-vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      rejectionModalVisible: false,
      refundModalVisible: false,

      selectedData: '',

      // Tabs
      tabOptions: [
        // {
        //   key: 'status', label: 'All', // TODO: response status undefined = 'published'
        // },
        {
          key: 'status', label: 'Published', value: 'published', default: true,
        },
        {
          key: 'status', label: 'Waiting for Publication', value: 'waiting',
        },
        { key: 'status', label: 'Completed', value: 'completed' },
        { key: 'status', label: 'Canceled', value: 'canceled' },
      ],

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: ['view', 'create', 'update'],
    }
  },
  methods: {
    changeFields(val) {
      this.$store.commit('event/SET_TABLE_FIELDS', val)
    },
    loadData() {
      return this.$store.dispatch('event/getTableData')
    },
    filterData(val) {
      this.$store.commit('event/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('event/SET_TABLE_SORT', val)
    },
    toDetailListUser() {
    },
    loadDetail(id) {
      return this.$store.dispatch('event/getDetail', id)
    },
    paginate(val) {
      this.$store.commit('event/SET_TABLE_PAGINATION', val)
    },
  },
}
</script>
